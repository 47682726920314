import React from 'react'
import styled from "styled-components";

import facebook from '../img/social/facebook.svg'
import twitter from '../img/social/twitter.svg'
import linkedin from '../img/social/linkedin.svg'

const FooterElement = styled.footer`
  background: linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% );
  padding: 0 !important;
`;

const Footer = class extends React.Component {
  render() {
    return (
      <FooterElement className="footer has-text-white-ter">
        <div className="content has-text-centered has-text-white-ter">
          <div className="container has-text-white-ter">
            <div className="columns">
              <div className="column is-4" style={{paddingBottom: "0px"}}>
              </div>
              <div className="column is-4" style={{paddingBottom: "0px"}}>
                <b>TunaSec z.s.</b><br/>
                Moldavská 527/3, Bohunice, 625 00 Brno<br/>
                <strong style={{color: "#FFFFFF"}}>
                  <img src={"/img/layout/footer-phone-icon.svg"} style={{width: "18px", top: "-2px", verticalAlign: "middle"}} alt={"Phone"}/> &nbsp;+420 774 071 231<br/>
                  <img src={"/img/layout/footer-email-icon.svg"} style={{width: "18px", top: "-2px", verticalAlign: "middle"}} alt={"Envelope"}/> &nbsp;info@tunasec.com<br/><br/>
                </strong>
              </div>
              <div className="column is-4 social" style={{paddingBottom: "15px", margin: "auto auto"}}>
                <a title="facebook" href="https://www.facebook.com/tunasec.cz">
                  <img
                      src={facebook}
                      alt="Facebook"
                      style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com/tunasec_cz">
                  <img
                      className="fas fa-lg"
                      src={twitter}
                      alt="Twitter"
                      style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="linkedin" href="https://cz.linkedin.com/company/tunasec">
                  <img
                      src={linkedin}
                      alt="Linkedin"
                      style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </FooterElement>
    )
  }
}


export default Footer
