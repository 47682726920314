import React from 'react'
import {Link} from 'gatsby'
import styled from "styled-components";

const LanguageToggle = styled(Link)`
  position: absolute;
  right: 10px;
  top: 6px;
  font-weight: bold;

  > img {
    vertical-align: top;
    top: 0;
  }

  @media (max-width: 1024px) {
    position: inherit;
  }
`;

const Navbar = class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            mounted: false,
            navBarActiveClass: '',
        }
    }

    componentDidMount()
    {
        this.setState({mounted: true})
    }

    toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState(
            {
                active: !this.state.active,
            },
            // after state has been updated,
            () => {
                // set the class in state for the navbar accordingly
                this.state.active
                    ? this.setState({
                        navBarActiveClass: 'is-active',
                    })
                    : this.setState({
                        navBarActiveClass: '',
                    })
            }
        )
    }

    render() {
        const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

        return (
            <nav
                className="navbar is-transparent"
                role="navigation"
                aria-label="main-navigation"
            >
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item" title="Logo">
                            <img src="/img/tunalogo.webp" alt="Tunasec" style={{height: '28px', marginTop: '3px'}}/>
                        </Link>
                        {/* Hamburger menu */}
                        <div
                            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                            data-target="navMenu"
                            onClick={() => this.toggleHamburger()}
                        >
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    <div
                        id="navMenu"
                        className={`navbar-menu ${this.state.navBarActiveClass}`}
                    >
                        {currentUrl.indexOf("/en") > -1 ?
                            <div className="navbar-start has-text-centered">
                                <Link className="navbar-item" to="/en/about-us/">
                                    <b>About us</b>
                                </Link>
                                <Link className="navbar-item" to="/en/products/">
                                    <b>Products</b>
                                </Link>
                                <Link className="navbar-item" to="/en/cooperation/">
                                    <b>Cooperation</b>
                                </Link>
                                <Link className="navbar-item" to="/blog/">
                                    <b>Blog</b>
                                </Link>

                                <LanguageToggle className="navbar-item" to="/" style={{right: "10px"}}>
                                    CZ <img alt={"cs"} src={czflag} style={{marginLeft: "6px"}}/>
                                </LanguageToggle>
                            </div>
                            :
                            <div className="navbar-start has-text-centered">
                                <Link className="navbar-item" to="/o-nas/">
                                    <b>O nás</b>
                                </Link>
                                <Link className="navbar-item" to="/nase-aktivity/">
                                    <b>Naše aktivity</b>
                                </Link>
                                {/* TODO: Add WebScan once it's ready :)
                                // <Link className="navbar-item" to="/webscan/">
                                //     <b>WebScan</b>
                                // </Link>
                                */}
                                <Link className="navbar-item" to="/reference/">
                                    <b>Reference</b>
                                </Link>
                                <Link className="navbar-item" to="/blog/">
                                    <b>Blog</b>
                                </Link>
                                <Link className="navbar-item" to="/kontakt/">
                                    <b>Kontakt</b>
                                </Link>
                                <LanguageToggle className="navbar-item" to="/en/" style={{right: "10px"}}>
                                    EN <img alt={"en"} src={ukflag} style={{marginLeft: "6px"}}/>
                                </LanguageToggle>
                            </div>
                        }
                        <div className="navbar-end has-text-centered">
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar

const czflag = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAALXSURBVHja7JdNiBRXFIW/Wz/TjmHojIMGQhbBiJGIC3EhCpqNrhXcJbsgSSAgEzRxo6ig7kXGjQtRFAQJIggSF9pRyaioIfFvnPiXARcmjg7TXVVdVe+966KnTY+IjEN3zcYDxatNcU+dc+/lPFFVZhIeM4z3BATwgdLEWSQskAL0Dhy7pvXUaNEAegOgfOr0Hcqzu1i7ej7z5nxQpAplDyjZWQEPR16w79BVBv94UiSBkgfgrKPulLFqytFf/uTIyZtESV7cFOTWEddzktySqKNy8QF7D1zi1r1/iyFgjBLFOXGSEyeGGLj/eJTdAxc5ceZuRwkEAMY6xqOM2uuyW8vh4zcYfjTK918to+/D7s4QyI2lGqVv9F2Bs+eH+evuU/q/Wc6KpZ90oAeMo1pLieJs0lOLMuI4I1LhwcgLNu36lYGj10gz22YLTMOCemYm1qOg6Kv3V2qosv/gIJXLj9nZ/yWLF8xtyyr+fP6agSE8yHM3pY80yukud/PzD6vZuO6L6RcXWRQ0LajFGbmZIgEJGXs6zt+bt/Hwx9vvXjkM8Xt6WprQWox1WPf2cKIISMhn+TN2RRVW5f9QdQHvHGmcQphO7gFVcG8hoOIDwtfxDTYnl+nThKp0gUdLl0xRes+jmcQCAGscCrg3xDMFkC76bI3t0W9sSIfIxCciAHXTM18VzbPWPWAAD31NgabkK9NH7InOsdCOUpMSDZ5u+q2vijG2dRNa/MCntX5Dco9voytsTX4nVNeQvB0hVgRrzGQLfL8x503JPzZjbIsvsD69R10C6vjtKd78QdeigFOLCCgeSMDadJjdUYVP3RhVKdHu4C4iWOf+J6DqwAvpImdLVOG75DqCMC6lDiVRwbUScBqy0I6yw1VY4T8h6injkI5G5ubIB4Bdl97ip+eD9FFnhLAZVgtJxgJ8BCwB5lIs/gNuysSdoGfiLBIpUJX3l9OZJvByACmB5UHd8MJgAAAAAElFTkSuQmCC";

const ukflag = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA3QAAAN0BcFOiBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAATuSURBVFiF7ZdrUNRlFMZ/+2dhXVoW5DJcAgTjamopziiWYuhMYpHGRUk0Gs1gIshboIYMoWNqahQyig41hJLXcgSvQ6VyS2WE1IBABRSZ5b673BZYdvtQ4QUYHb7QB55v7zlz5nnOvO+c57wivV7PSEIYUfZRAf8HAeJL65MbJ8UuvSQ2EveVPewy3naqxkPZ0SsZqmD+KxYPI9+0u9/iH+n9eNw8K6Uw5UKd4/k/ml98FqmrjVQd/65DhTg1015suzvFsuHM+SDV6nA8Q3xIWeHCrqxaUnPq6NbqBhR7jTdxBLx7coueTjk2tvWQW64aktja1IiNixxZ0ldDh1/CRM3tSgR1oD/CnWrGRsRQ89YnNBf9ReJiJ4q+9CJ4hhUi0bP6eTakRgIx/g4UhZmxMHk7qoBotOX3MF4ZiJDptwz1qTQ0Pt5I8q8jXfghle8nIGlt5eBH7vwS9yoz3UyHRSyIIPR1a4rXOhP56yE6fMPozilEMn8WlgWZyHfHICSdrcXvmJqL0bG0pu2hz9MFk9Pn0M8OoXRdMu5mcHbDJDKjPHG1kT43ue/LZuTFerKj/jdEc96jK+M0hpPdMM/ex9gju1Db2bMh8x4iArL7R6GLjZSEwHE45ediuf97qKsHK3OaVoXhuSYYvYFAVYMGVxspCrPpTxDaKK+ScKKaizdb2RrkyIziXNq3HUBX34yBvQ2yzRFIF89Ho9WTmlPH7uxa1F3aJwX8B283OYnv2GF8+CcsM46iV7ejc3VGGb2KCcvnAgwqoKpBg23JDdrjk9GW30MklyFbG4ZxRAhIjDh+tZEtJ6t50NzdXyc6kVs1qBkIgoiZrnI661tQXLn1iMhnMub2FoMK0LWo6L1R2h8znOKJYGEGQF1rN6W1nQN4REqlclhuNJiA4UBUjvuI2uGIj+JRASP+CMU5t1oHBCfYG2M39h9D1DUr6S0u688ZTp2AYD74aG6pbUZx+eYjUbMnYWxtTkGlGp1u8D7FQV//2X9wsJCwOdAJWzMJek0PnfuP0L4nHb26HbHHeGSJUdRoJTgP0Y25vQUKnQ6zbw8iVFbRK5dxd/kSTEIDiD9TR2GFekCNACCXivki2Inr27xYPN0SzbFzNE0Lpi0hBZFUgvybjdw8sBffPCnpVxRD0EOlogu3UF+sCw7TtGkNeokRFilpOASt4CvjuxyK9MDlKT8RIubZUbJjGp/62SNcLaH5jQ9QhSega1Ehi11JU1YGy5pcCEgqo7S2Y0hygMN59XjH3eDCbSUTY0IwuXaclpXLECnVWMZvZ0r0GjK8NOwMfQkLmSHw7yPUVlTTFr+X7vO5IAhIQ9+mK2oFW/La+TG/nsevb/UCexKCnIY0o6SztQDMdDNl6xJnpjrLaLijQJW4H5PsC6DTofHxpuezcDIVUsTqdTvpTD8F2j4k87wRx31McpWE5D1VdPUM3IieFwUVKuZuLSFouhXxgU64/pBAVW4wwvZ9jLlcyJj8ayxduABxZ9pJxBNdeSExiqMG4/gy/T71qp5hEz8OvR6O/97I6aJmwufZsd7fHfmZvZQduYxpUiryk1mIZXER17ThS2s///mBW6WiUe5mK8XNdujFw8rE6CFw32jWNO+nUoVWJkaOszxMB11Ki6vbiPzuTvemRePKPUN8OrUBrxnc2pE5RzT6NRsVMNIC/gaY0PLtJgQJ0gAAAABJRU5ErkJggg=="